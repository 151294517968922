import { pathnameContainsNonRunnablePages, makeGatewayCallPromise } from './dependecies/logincheck.api.es6';
import { ME_SESSION_STORAGE_KEYS } from './dependecies/mesessionstoragekeys.es6';
import { logIfDebugEnabled, DEBUG_SESSION_KEY } from './dependecies/utils.es6';
import MD5 from './dependecies/md5HashGenerator.es6';

const LANDING_PAGE_PARAMETER = 'ONEDOC_SPA'; // OneWeb_registration

const ONE_DAY_IN_MS = 24*60*60*1000;

let loginCheckParameters;

let _fetchDataUsageScopes = function (loginCheckParameter = {}) {
    const { marketConfig, b2xLoginData, bffURL } = loginCheckParameter;

    var country = b2xLoginData.country;
    var legalentity = marketConfig.legalentity;
    var token = b2xLoginData.authState.jwe;
    const body = _createRequestBody(country, legalentity);

    return makeGatewayCallPromise(bffURL, token, body);
  },
  _createRequestBody = function (countryCode, legalEntity) {
    var dataScopesSecretHash = '123456';
    const body = {
      searchContext: {
        legalEntity: legalEntity,
        system: LANDING_PAGE_PARAMETER,
      },
      purpose: 'Direct Marketing',
      crossCustomerTypeSearchAllowed: true,
      customerSearchData: {
        customerType: 'Person',
      },
      hashCode: MD5(
        [
          countryCode, // market
          LANDING_PAGE_PARAMETER, // system
          legalEntity, // legalEntity
          '', // companyName1
          '', // companyName2
          '', // externalKeyIssuer
          '', // externalKeyValue
          dataScopesSecretHash, 
        ].join(';'),
      ),
    };

    return JSON.stringify(body);
  },
  _writeDataUsageScopesToStorage = function (response = {}) {
    window.sessionStorage.removeItem(ME_SESSION_STORAGE_KEYS.dataUsageScopesTimeStampFailed);

    const { customerConsentState, customerFound } = response;

    if (customerConsentState) {
      const { enableDataUsage } = customerConsentState;
      window.sessionStorage.setItem(ME_SESSION_STORAGE_KEYS.declarationOfConsent, JSON.stringify(enableDataUsage));
    }

    if (customerConsentState && customerFound) {
      const { dataUsageScopes } = customerConsentState;
      if (dataUsageScopes) {
        logIfDebugEnabled('Write dataUsageScopes to session storage', dataUsageScopes);
        window.sessionStorage.setItem(ME_SESSION_STORAGE_KEYS.dataUsageScopes, JSON.stringify(dataUsageScopes));
        logIfDebugEnabled('Write dataUsageScopes time stamp in MS to session storage');
        window.sessionStorage.setItem(ME_SESSION_STORAGE_KEYS.dataUsageScopesTimeStamp, JSON.stringify(Date.now()));
      }
    }
  },

  _writeFalseDataUsageScopesToStorage = function () {
    window.sessionStorage.removeItem(ME_SESSION_STORAGE_KEYS.dataUsageScopesTimeStamp);
    window.sessionStorage.setItem(ME_SESSION_STORAGE_KEYS.dataUsageScopesTimeStampFailed, JSON.stringify(Date.now()));        

    // Set data usage to false in case of error response from BFF
    const dataScopes = window.sessionStorage.getItem(ME_SESSION_STORAGE_KEYS.dataUsageScopes);

    if(dataScopes){
      window.sessionStorage.setItem(ME_SESSION_STORAGE_KEYS.dataUsageScopes, dataScopes.replaceAll('true', 'false'));      
    }
  },

  _getTimePassed = function(timeStamp){
    return Date.now() - parseInt(timeStamp);
  },

  _makeDataUsageScopesRequestAndValidateResponse = function (loginCheckParameter, skipRecommendation) {
    _fetchDataUsageScopes(loginCheckParameter)
      .then((dataUsage) => {
        logIfDebugEnabled('Request for dataUsageScopes succeeded');
        _writeDataUsageScopesToStorage(dataUsage, skipRecommendation);
      })
      .catch(() => {
        logIfDebugEnabled('Request for dataUsageScopes failed');
        _writeFalseDataUsageScopesToStorage();       
      });
  };

export default function declarationOfConsent(loginData) {
  window.sessionStorage.setItem(DEBUG_SESSION_KEY, true);

  logIfDebugEnabled('Start loading', loginData);

  loginCheckParameters = loginData;

  const { marketConfig } = loginCheckParameters;

  if (!marketConfig.isEnabled) {
    logIfDebugEnabled('Not executing because logincheck is disabled');
    return;
  }

  if (marketConfig && pathnameContainsNonRunnablePages(marketConfig.nonRunnablePages)) {
    logIfDebugEnabled('Not executing because logincheck is disabled on this page');
    return;
  }

  const dataUsageScopesTimeStamp = window.sessionStorage.getItem(ME_SESSION_STORAGE_KEYS.dataUsageScopesTimeStamp);
  const dataUsageScopesTimeStampFailed = window.sessionStorage.getItem(ME_SESSION_STORAGE_KEYS.dataUsageScopesTimeStampFailed);

  // In case request to BFF failed do retry in 1 day
  if (dataUsageScopesTimeStampFailed) {
    const timePassedMs = _getTimePassed(dataUsageScopesTimeStampFailed);

    if (timePassedMs < ONE_DAY_IN_MS) {
      logIfDebugEnabled('Next retry will be in: '+ Math.round((-1) * timeLeftMs / (60*1000)) + ' minutes');
      return;
    }
  }

  // Repeat DoC request in 5 days
  if (dataUsageScopesTimeStamp) {
    const timePassedMs = _getTimePassed(dataUsageScopesTimeStamp);

    if (timePassedMs < (5 * ONE_DAY_IN_MS)) {
      logIfDebugEnabled('Not executing because dataUsageScopes are still valid, execution in: '+ Math.round((-1) * timeLeftMs / (60*1000)) + ' minutes');
      return;
    }
  }

  _makeDataUsageScopesRequestAndValidateResponse(loginCheckParameters);
}
